jQuery(window).on("load", function () {

    if ($("header").offset().top > 100) {
        setTimeout(function () {
            $('header').addClass('menu-up menu-down');
        }, 100);
    }

    $('.reviews .slider').css('opacity', '1');

      [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function () {
            img.removeAttribute('data-src');
        };
    });

    var isCall = false;
    var window_scroll = $(window).scrollTop() + $(window).height();
    var element_pos = $('.counter .number').offset().top;
    var winScrollTop = $(this).scrollTop();
    var scrollToElem = $('.counter .row').offset().top - $(window).height();
    var check = true;

    function counter() {
        setTimeout(function () {
            if (!isCall) {
                $('.number').each(function () {
                    var $this = $(this);
                    jQuery({
                        Counter: 0
                    }).animate({
                        Counter: $this.attr('data-number')
                    }, {
                        duration: 1000,
                        easing: 'swing',
                        step: function () {
                            $this.text(Math.ceil(this.Counter));
                        }
                    });
                });
                isCall = true;
            }
        }, 200)
        setTimeout(function () {
            $('.plus').show();
        }, 300)
    }

    if (window_scroll >= element_pos && element_pos + $('.counter .row').height() > $(window).scrollTop()) {
        if (!isCall) {
            counter();
        }
    }

    $(window).scroll(function () {
        window_scroll = $(window).scrollTop() + $(window).height();
        element_pos = $('.counter .number').offset().top;
        if (window_scroll >= element_pos && element_pos + $('.counter .row').height() > $(window).scrollTop()) {
            counter();
        }
    })

});

$(document).ready(function () {

    $("#menu").mmenu({
        "extensions": [
                    "position-right",
                    "pagedim-black",
                    "border-offset"
                 ],
        classNames: {
            fixedElements: {
                fixed: "fix-menu"
            },
            vertical: "expand"
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.icon').removeClass('open');
    });

    $("li.mm-listitem").click(
        function (evnt) {
            api.close();
            $('.icon').removeClass('open');
        }
    )

    $('.reviews .slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
          ]
    });

    $(".line1, .line2").paroller({
        factor: 0.13,
        factorXs: 0.13,
        type: 'foreground',
        direction: 'horizontal'
    });

    if ($(window).width() < 992) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > $(window).height()) {
                $('.fix-menu').addClass('mh-scrolledout');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }
            if (st > lastScrollTop) {
                $('.fix-menu').addClass('menu-down');
            } else if (st == 0) {
                $('header').removeClass('menu-down');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }

            lastScrollTop = st;

        });

    } else {

        var heightHeader = $('header .container').height();
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();

            if (st > $(window).height()) {
                $('header').addClass('menu-up');
            } else {
                $('header').removeClass('menu-up');
            }
            if (st > heightHeader) {
                $('header').addClass('menu-down');
            } else {
                $('header').removeClass('menu-down');
            }
            if (st < lastScrollTop) {
                $('header').removeClass('menu-up');
            }

            lastScrollTop = st;
        });
    }

    var location = window.location.href;
    var cur_url = location.split('/').pop();
    $('header ul li').each(function () {
        var link = $(this).find('a').attr('href');

        if (cur_url == link) {
            $(this).addClass('current');
        }
    });

    $('.lang.mm-listitem a').each(function () {
        var link = $(this).attr('href');
        if (cur_url == link) {
            $(this).addClass('current');
        }
    });

    $('.lang .wrap').click(function () {
        $(this).next().toggleClass('show');
        $(this).toggleClass('show');
    });

    $(document).mouseup(function (e) {
        var container = $(".lang");
        if ($(".lang").has(e.target).length === 0) {
            $('.lang ul').removeClass('show');
            $('.lang .wrap').removeClass('show');
        }
    });

    $('.jarallax').jarallax({
        speed: 0.2
    });

    $('.scroll').on("click", function (event) {
        event.preventDefault();
        var anchor = '#' + $(this).attr('href').split('#')[1];
        $('body,html').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });

    $('.first-screen form').validate({
        submitHandler: function (form) {
            $('.first-screen form').ajaxSubmit({
                //clearForm: true,

                success: function (data) {
                    if (data.length > 0) {
                        $('.toast-body').css('color', '#28a745');
                        $('.toast-body').text($('.contact form').data('success'));
                        $('.toast').toast('show');
                        $('.first-screen form').trigger('reset');
                    } else {
                        $('.toast-body').css('color', '#f1202d');
                        $('.toast-body').text($('.contact form ').data('error'));
                        $('.toast').toast('show');
                    }
                },
                error: function (data) {
                    $('.toast-body').css('color', '#f1202d');
                    $('.toast-body').text($('.contact form ').data('error'));
                    $('.toast').toast('show');
                }
            });
        }
    });

    $('.contact form').validate({
        submitHandler: function (form) {
            $('.contact form').ajaxSubmit({
                //clearForm: true,

                success: function (data) {
                    if (data.length > 0) {
                        $('.toast-body').css('color', '#28a745');
                        $('.toast-body').text($('.contact form').data('success'));
                        $('.toast').toast('show');
                        $('.contact form').trigger('reset');
                    } else {
                        $('.toast-body').css('color', '#f1202d');
                        $('.toast-body').text($('.contact form ').data('error'));
                        $('.toast').toast('show');
                    }
                },
                error: function (data) {
                    $('.toast-body').css('color', '#f1202d');
                    $('.toast-body').text($('.contact form ').data('error'));
                    $('.toast').toast('show');
                }
            });
        }
    });

});
